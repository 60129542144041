export const cyrillicRegex = /^[\p{Script=Cyrl}\d\s+-]*$/u; // match cyrillic, numbers, dash and spaces

export const passwordValidationRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/; // length greater than or equal to 6, contains at least 1 Uppercase letter, 1 Lowercase letter and 1 Number

export const bulstatValidationRegex = /^[0-9]{9,13}$/;

export const phoneNumberValidationRegex = /^[0-9]+$|^$/;

export const bgNumberRegex = /^(?:\+359|0)([89][7-9][0-9]{7})$/;
export const latinLettersRegex = /[a-zA-Z]+/gu;
